function domain_connector_submit(e) {

    if (!jQuery('input[name=dc_hp]').is(':checked') || !jQuery('input[name=dc_wb]').is(':checked'))
    {
        jQuery('#domain-error').css('display', 'block');

        return false;
    } else
    {
        jQuery('#domain-error').css('display', 'none');
    }

    var combination = jQuery('input[name=dc_hp]:checked').val() + ' ' + jQuery('input[name=dc_wb]:checked').val() + ':';

    var url = '';

    var dc_hp = parseInt(jQuery('input[name="dc_hp"]:checked').val());
    var dc_wb = parseInt(jQuery('input[name="dc_wb"]:checked').val());

    jQuery(target_url).each(function (k, v)
    {


        if (dc_hp === v.hosting_provider.ID && dc_wb === v.website_builder.ID)
        {

            document.domain_connector.action = v.linked_page;
            document.domain_connector.submit();
        }

    });

    //document.domain_connector.action = url;
    //document.domain_connector.submit();
}

(function ($)
{
    $(document).ready(function ()
    {
        $('#domain-connector-form').submit(function (e)
        {
            e.preventDefault();
            domain_connector_submit();
        });
    });
})(jQuery);