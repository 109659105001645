jQuery(document).ready(function($) {
	$(".global-nav__item").on({
		mouseenter: function () {
			$(this).addClass('is-active');
			$(this).find('.global-nav__group').addClass('is-active');
		},
		mouseleave: function () {
			$(this).removeClass('is-active');
			$(this).find('.global-nav__group').removeClass('is-active');
		}
	});

	// Open language selector on hover
	// $('.language-selector--desktop .language-selector__select').hover(function() {
	// 	console.log('test');
	// 	$(this).attr('size', $('option').length);
	// }, function() {
	// 	$(this).attr('size', 1);
	// });
});
