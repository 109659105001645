jQuery(document).ready(function($) {
   var resizeDelay = 200;
   var doResize = true;
   var resizer = function () {
      if (doResize) {

        $('.review-header-wrap').css({'height': $('.review-header').outerHeight()});

		// console.log($('.review-header').outerHeight());

        doResize = false;
      }
    };
    var resizerInterval = setInterval(resizer, resizeDelay);
    resizer();

    $(window).resize(function() {
      doResize = true;
    });
});
