jQuery(document).ready(function($) {
	var versus_delimeter = '-vs-';

 	$(".remove-it").click(function(t) {
        t.preventDefault();
        var i = new Array;
        var o = $(this).closest("th").index() + 1;
        $("#side-by-side-table tr td:nth-child(" + o + ")").remove();
        $("#side-by-side-table tr th:nth-child(" + o + ")").remove();
        var n = "" + i.join(versus_delimeter) + "/";
        var i = $("#side-by-side-table > tr:first-child td").length, n = 6 - i;
        $("#side-by-side-table").css("left", 8.33 * n + "%");

        var s = $(this).attr("data-review-id");
        $('div[data-review-id="' + s + '"]').removeClass("selected");
    });

    $(".comparison__modal--selector").click(function (t) {
        t.stopPropagation(), t.preventDefault();
        var o = $(".comparison__modal--selector.selected");
        if (o.length >= 5 && !$(this).hasClass("selected"))
            return $(".error-too-much").css("display", "block"), $(".error-not-enough").css("display", "none"), !1;
        $(this).toggleClass("selected");
        var o = $(".comparison__modal--selector.selected"),
                i = new Array;
        if (o.length > 1) {
            $(".error-too-much").css("display", "none"), $(".error-not-enough").css("display", "none"), $("#side-by-side-button").removeClass("btn-gray"), $(o).each(function (t, o) {
                i.push($(o).attr("data-url"))
            });
            var n = "" + i.join(versus_delimeter) + "/";
            $("#side-by-side-button").attr("href", home_url + n)
        } else {
            $(".error-too-much").css("display", "none"), $(".error-not-enough").css("display", "block"), $("#side-by-side-button").addClass("btn-gray"), $("#side-by-side-button").attr("href", "#")
        }
    });

    $("#side-by-side-button").click(function () {
        return $(this).hasClass("btn-gray") ? !1 : void(window.location = $(this).attr("href"))
    });
});

//Code above has been dissected from below

!function (j) {

    var domain = location.protocol+'//'+location.hostname+(location.port ? ':'+location.port : '');

    //j(".compare-checkbox").mouseout(function() { return !1})
    function t(t) {
        if (j(t).hasClass("non-checkable"))
            return !1;
        if (j(t).hasClass("compare-checkbox")) {
            var o = j(".compare-checkbox.checked").length;
            if (o >= 5 && !j(t).hasClass("checked"))
                return j("#max-modal").modal("show"), !0
        }
        // j(t).toggleClass("checked"), j(t).closest(".comparison-row").toggleClass("row-checked")
    }
    setInterval(function () {
        j(".tooltip").off("click").on("click", function () {
            if (typeof j("#compare-button").attr("href") !== 'undefined')
            {
                j("#compare-button").hasClass("btn-gray") || (window.location = j("#compare-button").attr("href"))
            }

        }), j(".tooltip").off("touched").on("touched", function () {

            if (typeof j("#compare-button").attr("href") !== 'undefined')
            {
                j("#compare-button").hasClass("btn-gray") || (window.location = j("#compare-button").attr("href"))
            }

        })
    }, 500),

    navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPod/i) ?
        (   d = "touchend",
            j(".checkbox").on("touchend", function (j) {
                t(this)
            })
        ) : (   d = "click",
                j(".checkbox").on("click", function (j) {
                    j.stopPropagation(), t(this)
            })
        );

    j(".compare-checkbox").on(d, function () {
        var t = j(".compare-checkbox.checked"),
        o = new Array,
        i = j("#compare-button").attr("data-label");
        if (t.length > 1) {
            i += " (" + t.length + ")",
            j("#compare-button").removeClass("btn-gray"),
            j(t).each(function (t, i) {
                o.push(j(i).attr("data-url"))
            });
            var n = "" + o.join(versus_delimeter) + "/";
            j("#compare-button").attr("href", home_url + "/" + n)
        }
        else
            j("#compare-button").addClass("btn-gray"),
            j("#compare-button").attr("href", "#");
            j(".compare-checkbox").each(function (t, o) {
                var n = "";
                if (j(o).hasClass("checked")) {
                    j(o).removeClass("dark-tooltip"),
                    j(o).addClass("orange-tooltip"),
                    j(o).attr("title", i);
                    var n = i
                } else {
                    j(o).addClass("dark-tooltip"),
                    j(o).removeClass("orange-tooltip"),
                    j(o).attr("title", j(o).attr("initial-title"));
                    var n = j(o).attr("initial-title")
                }
            }),
            j("#compare-button").html(i);
    });
    // Compare button on click event
    j("#compare-button").click(function (t) {
        j(this).hasClass("btn-gray") && (t.preventDefault(), j(".comparison-row").each(function (t, o) {
            var i = j(this).find(".compare-checkbox").not(".checked");
            if (0 === j(o).find(".tooltip").length) {
                var n = j('<div class="tooltip fade top in" role="tooltip" id="tooltip445714" style="top: -38px;transform: translateX(-50%); left: 40px; display: block;"><div class="tooltip-arrow" style="left: 45.9459%;"></div><div class="tooltip-inner">'+compare_text+'</div></div>');
                j(window).width() < 768 && (j(n).css("left", "-20px"), j(n).find(".tooltip-arrow").css("left", "26.5%")),
                j(i).after(n),
                setTimeout(function () {
                    j(n).remove()
                }, 1e3)
            }
        }))
    });
    // On mouse out from checkbox
    j(".compare-checkbox").mouseout(function (j) { return !1 });
    navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPod/i) || j(".comparison-row").hover(function () {
        var t = j(this).find(".compare-checkbox");
        0 === j(this).find(".tooltip").length && (j(t).trigger("mouseover"), j(".orange-tooltip + .tooltip").click(function () {
            var t = j(".compare-checkbox.checked");
            t.length > 1 && (window.location = j("#compare-button").attr("href"))
        }))
    }, function () {
        var t = j(this).find(".compare-checkbox");
        j(this).find(".tooltip").length > 0 && j(t).trigger("mouseout")
    })

    //  change background
    j("a.internal-link").click(function (t) {
        t.preventDefault();
        var dataHref = j(this).attr("data-href");
        var linkText = j(this).text(); // get the title of selected website type
        var imgName  = j(this).attr("data-name"); // get image file name
        j("#starting-link").prop("href", dataHref); // change url after click
        j("#select-website-type.bg-image").css('background-image', 'url(' + the_home_url + '/wp-content/themes/tooltester-child/images/homepage/' + imgName + ')');
        j("#starting-link").html( linkText );
        setTimeout( function (){window.location.href = dataHref; }, 2000 );
    });
    //  change background on hover
    j("a.internal-link").mouseover(function (t) {
        t.preventDefault();
        var imgName  = j(this).attr("data-name"); // get image file name
        j("#select-website-type.bg-image").css('background-image', 'url(' + the_home_url + '/wp-content/themes/tooltester-child/images/homepage/' + imgName + ')');
    });

    // Load more reviews button
    j(".more-reviews").click(function (t) {
        t.preventDefault(),
        j(".hidden-review").slice(0, 4).removeClass("hidden-review"),
        0 === j(".hidden-review").length && j(this).fadeOut(300), s()
    })

    j('#goto-builders-in-detail').click(function (t) {
        j("html,body").animate({
            scrollTop: j("#builders-in-detail").offset().top
        });
    });

    !function (e) {
    var r, l = !1;
    e(window).load(function () {
        s(), e("#side-by-side-table > tr").each(function () {
            var t = e(this).height();
            e(this).find("td").height(t - 40), e(this).find(".dashed-border").height(t - 95)
        }), e("#side-by-side-table").css("opacity", "1")
    }), e(window).scroll(function () {
        n()
    }), e(window).resize(function () {

        if(e(window).width()>977)
        {
            e("#sticky").stick_in_parent({
                offset_top: l,
                inner_scrolling: !1
            });
        }
        else
        {
            e("#sticky").trigger("sticky_kit:detach");
        }

        e(window).width() < 992 ? e("#left-column").prepend(e("#sticky")) : e("#right-column").prepend(e("#sticky")), c(), a(), o();


        if(jQuery(window).width()>=992)
        {
            jQuery('#right-column').append(jQuery('#sticky'));
        }

        /*e(window).width() < 992 ? e("#left-column").prepend(e("#sticky")) : e("#right-column").prepend(e("#sticky")), c(), a(), o()*/
    }), c(), e(document).ready(function () {

        function t(t) {
            if (e(t).hasClass("non-checkable"))
                return !1;
            if (e(t).hasClass("compare-checkbox")) {
                var o = e(".compare-checkbox.checked").length;
                if (o >= 5 && !e(t).hasClass("checked"))
                    return e("#max-modal").modal("show"), !0
            }
            e(t).toggleClass("checked"), e(t).closest(".comparison-row").toggleClass("row-checked")
        }
        e(".white-tooltip.big-and-bold").each(function (t, o) {
            var i = e(o).attr("title");
            "" !== i && e(o).addClass("has-tooltip-add-underline")
        }), e("#sticky-box-2 a").click(function () {
                setTimeout(waitme, 1000,this);
                function waitme(el){window.location.hash = e(el).attr("data-hash");}
        }), e("#latest-update").click(function () {
            e(this).find("span").toggle()
        }), setInterval(function () {
            e(".tooltip").off("click").on("click", function () {
                if (typeof e("#compare-button").attr("href") !== 'undefined')
                {
                    e("#compare-button").hasClass("btn-gray") || (window.location = e("#compare-button").attr("href"))
                }

            }), e(".tooltip").off("touched").on("touched", function () {

                if (typeof e("#compare-button").attr("href") !== 'undefined')
                {
                    e("#compare-button").hasClass("btn-gray") || (window.location = e("#compare-button").attr("href"))
                }

            })
        }, 500), e(".menu-item-has-children").append(e('<span class="mobile-arrow mobile"></span>')),
            e(".language-wrapper").hover(function () {
                //e(this).parent().find(".the-dropdown").toggle() //e(this).parent().find(".the-dropdown").slideDown(500)()
            },
            function () {
            e(this).parent().find(".the-dropdown").toggle() //e(this).parent().find(".the-dropdown").slideUp(500)
        }), e("#review-table td:nth-child(2)").each(function (t, o) {
            e(o).prepend(e('<div class="fromFirstColumn"></div>'))
        }), c(), e("#menu-top-menu .mobile-arrow").click(function (t) {
            if (e(window).width() < 992) {
                var o = e(this).prev("ul");
                e(o).length > 0 && (t.preventDefault(), e(this).toggleClass("opened"), e(o).slideToggle(300))
            }
        }), o(), e(".slide").click(function (t) {
            t.preventDefault(), e("body,html").animate({
                scrollTop: e("#step-by-step").offset().top
            }, 1300)
        }), e("#sticky-box-2 a").click(function () {}), e(".white-tooltip").each(function (t, o) {
            var i = e(o).attr("title");
            "" === i && e(o).css("border-bottom", "0px")
        }), e(".modal").on("hidden.bs.modal", function () {
            i()
        }), e("#single-post blockquote").each(function (t, o) {
            e(o).find("p").contents().unwrap(), e(o).html("“" + e(o).html() + "”")
        }), r = setInterval(function () {
            e(".gsc-input").length > 0 && (e(".gsc-input").attr("placeholder", "Search..."), clearInterval(r))
        }, 500), e(window).width() <= 977 && e(".swipeable > div").draggable({
            axis: "x",
            scroll: !0,
            drag: function (t, o) {
                o.position.left = Math.min(0, o.position.left), o.position.left = Math.max(-1187 + e(window).width() - 30, o.position.left)
            }
        }), e(window).width() >= 1025 && e(".one-section.top-10-app-makers").sticky({
            topSpacing: 0
        }), e(window).width() < 992 && e("#left-column").prepend(e("#sticky")), e(".one-quote-person").click(function (t) {
            t.preventDefault(), e(".one-quote-person").removeClass("current"), e(this).addClass("current");
            var o = e(this).attr("rel");
            e(".one-testimonial").addClass("test-hidden"), e('.one-testimonial[rel="' + o + '"]').removeClass("test-hidden")
        }), e(".language-clickable").click(function (t) {
            e(this).parent().find(".the-dropdown").toggle() //t.preventDefault(),
        }), e(".remove-it").click(function (t) {
            t.preventDefault();
            var o = e(this).closest("th").index() + 1;
            e("#side-by-side-table td:nth-child(" + o + ")").remove();
            var i = e("#side-by-side-table > tr:first-child td").length,
                    n = 6 - i;
            e("#side-by-side-table").css("left", 8.33 * n + "%");
            var s = e(this).attr("data-review-id");
            e('div[data-review-id="' + s + '"]').removeClass("selected")
        }), e("#side-by-side-button").click(function () {
            return e(this).hasClass("btn-gray") ? !1 : void(window.location = e(this).attr("href"))
        }), e(".comparison__modal--selector").click(function (t) {
            t.stopPropagation(), t.preventDefault();
            var o = e(".comparison__modal--selector.selected");
            if (o.length >= 5 && !e(this).hasClass("selected"))
                return e(".error-too-much").css("display", "block"), e(".error-not-enough").css("display", "none"), !1;
            e(this).toggleClass("selected");
            var o = e(".comparison__modal--selector.selected"),
                    i = new Array;
            if (o.length > 1) {
                e(".error-too-much").css("display", "none"), e(".error-not-enough").css("display", "none"), e("#side-by-side-button").removeClass("btn-gray"), e(o).each(function (t, o) {
                    i.push(e(o).attr("data-url"))
                });
                var n = "" + i.join(versus_delimeter) + "/";
                e("#side-by-side-button").attr("href", home_url + "/" + n)
            } else
                e(".error-too-much").css("display", "none"), e(".error-not-enough").css("display", "block"), e("#side-by-side-button").addClass("btn-gray"), e("#side-by-side-button").attr("href", "#")
        }), e(".dashed-border,#add-to-sticky-wrapper .btn").click(function (t) {
            t.preventDefault(), t.stopPropagation(), e("#max-modal").modal("show")
        }),/* e(".on-hover").click(function () {
            var t = e(this);
            e.ajax({
                url: ajaxurl,
                data: {
                    action: "vote-review",
                    review_id: e(this).attr("data-review-id")
                },
                type: "post",
                success: function (o) {
                    e(t).addClass("voted")
                }
            })
        }),*/ e(".more-reviews").click(function (t) {
            t.preventDefault(), e(".hidden-review").slice(0, 4).removeClass("hidden-review"), 0 === e(".hidden-review").length && e(this).fadeOut(300), s()
        }), e(".more-posts").click(function (t) {
            t.preventDefault(), e(".hidden-post").slice(0, 9).removeClass("hidden-post"), e(".hidden-post-mobile").slice(0, 6).removeClass("hidden-post-mobile"), 0 === e(".hidden-post").length && e(window).width() > 992 && e(this).fadeOut(300), 0 === e(".hidden-post-mobile").length && e(window).width() <= 992 && e(this).fadeOut(300)
        }), e("#arrow-down").click(function (t) {
            t.preventDefault(), e("html,body").animate({
                scrollTop: e(".one-section").offset().top
            }, 1e3)
        });
        e('[data-toggle="tooltip"],.white-tooltip').not(".compare-checkbox").tooltip({
            html: !0,
            'delay': {show: 0, hide: 600}
        }), e(".compare-checkbox").tooltip({
            html: !0,
            'delay': {show: 0, hide: 600}
        }), e("#sticky-box-2 a").click(function (t) {
            t.preventDefault();
            var o = e(this).attr("rel");
            e("html,body").animate({
                scrollTop: e('*[data-observer="' + o + '"]').offset().top - 10
            }, 1e3)
        }), n(), e("#menu-toggle").click(function (t) {
            t.preventDefault(), e(".menu-expandable").toggle(300), e(this).toggleClass("open")
        }), e("#pro-cons-slider").slick({
            arrows: !1,
            dots: !0,
            adaptiveHeight: !0
        }), e(".arrow-slider").slick({
            arrows: !0,
            dots: !1,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [{
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                }]
        }),  e(".price-slider").slick({
            arrows: !0,
            dots: !1,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [{
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 4,
                                slidesToScroll: 4
                            }
                        },
                        {
                            breakpoint: 700,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3
                            }
                        },
                        {
                            breakpoint: 480,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2
                            }
                        }
                    ]
        }),a(), e(window).width() > 992 && (e("#right-column").height(e("#left-column").height()), setTimeout(function () {
            e("#right-column").height(e("#left-column").height())
        }, 1e3), setTimeout(function () {
            e("#right-column").height(e("#left-column").height())
        }, 6e3));
        var l = 30;
        e(window).width() < 768 && (l = 0), navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPod/i) || e(window).width() >= 977 && setTimeout(function () {
            e(".hide-on-scroll").css("display", "none"), e("#sticky").stick_in_parent({
                offset_top: l,
                inner_scrolling: !1
            }), e(".hide-on-scroll").css("display", "block"), e(".forceHidden").removeClass("forceHidden")
        }, 1500), e("#pro-cons-top a").click(function (t) {
            t.preventDefault(), e("#pro-cons-slider").slick("slickGoTo", parseInt(e(this).attr("data-slide")))
        }), e("#pro-cons-slider").on("beforeChange", function (t, o, i, n) {
            e("#pro-cons-top a").removeClass("active"), e('#pro-cons-top a[data-slide="' + n + '"]').addClass("active")
        }), e(".one-accordion h3,.one-accordion span").click(function () {
            var t = e(this).closest(".one-accordion");
            t.hasClass("closed") ? (e(".one-accordion div").slideUp(300), e(t).find("div").slideDown(300), e(".one-accordion").addClass("closed"), t.removeClass("closed")) : (e(".one-accordion div").slideUp(300), e(".one-accordion").addClass("closed"), t.addClass("closed"))
        });
        var d = "";
        navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPod/i) ? (d = "touchend", e(".checkbox").on("touchend", function (e) {
            t(this)
        })) : (d = "click", e(".checkbox").on("click", function (e) {
            e.stopPropagation(), t(this)
        })), e(".compare-checkbox").on(d, function () {
            var t = e(".compare-checkbox.checked"),
                    o = new Array,
                    i = e("#compare-button").attr("data-label");
            if (t.length > 1) {
                i += " (" + t.length + ")", e("#compare-button").removeClass("btn-gray"), e(t).each(function (t, i) {
                    o.push(e(i).attr("data-url"))
                });
                var n = "" + o.join(versus_delimeter) + "/";
                e("#compare-button").attr("href", home_url + "/" + n)
            } else
                e("#compare-button").addClass("btn-gray"), e("#compare-button").attr("href", "#");
            e(".compare-checkbox").each(function (t, o) {
                var n = "";
                if (e(o).hasClass("checked")) {
                    e(o).removeClass("dark-tooltip"), e(o).addClass("orange-tooltip"), e(o).attr("title", i);
                    var n = i
                } else {
                    e(o).addClass("dark-tooltip"), e(o).removeClass("orange-tooltip"), e(o).attr("title", e(o).attr("initial-title"));
                    var n = e(o).attr("initial-title")
                }
                e(o).attr("data-original-title", n).tooltip("fixTitle")
            }), e("#compare-button").html(i), e(this).tooltip("show")
        }), e("#compare-button").click(function (t) {
            e(this).hasClass("btn-gray") && (t.preventDefault(), e(".comparison-row").each(function (t, o) {
                var i = e(this).find(".compare-checkbox").not(".checked");
                if (0 === e(o).find(".tooltip").length) {
                    var n = e('<div class="tooltip fade top in" role="tooltip" id="tooltip445714" style="top: -38px; left: -45px; display: block;"><div class="tooltip-arrow" style="left: 45.9459%;"></div><div class="tooltip-inner">Click to compare</div></div>');
                    e(window).width() < 768 && (e(n).css("left", "-20px"), e(n).find(".tooltip-arrow").css("left", "26.5%")), e(i).after(n), setTimeout(function () {
                        e(n).remove()
                    }, 1e3)
                }
            }))
        }), e(".compare-checkbox").mouseout(function (e) {
            return !1
        }), navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPod/i) || e(".comparison-row").hover(function () {
            var t = e(this).find(".compare-checkbox");
            0 === e(this).find(".tooltip").length && (e(t).trigger("mouseover"), e(".orange-tooltip + .tooltip").click(function () {
                var t = e(".compare-checkbox.checked");
                t.length > 1 && (window.location = e("#compare-button").attr("href"))
            }))
        }, function () {
            var t = e(this).find(".compare-checkbox");
            e(this).find(".tooltip").length > 0 && e(t).trigger("mouseout")
        })
    })
}


}(jQuery);