const globalHeader = document.querySelector(".global-header");
const reviewHeader = document.querySelector(".review-header");
const reviewNav = document.querySelector(".review-sidebar-nav");
const topBanner = document.getElementById("topHeader");

const fixedGlobalHeader = new Headroom(globalHeader, {
	tolerance : 0, offset:0,
    classes : {
        initial : "global-header--headroom",
        pinned : "global-header--pinned",
        //unpinned : "global-header--unpinned",
        top : "global-header--top",
        notTop : "global-header--not-top",
        bottom : "global-header--bottom",
        notBottom : "global-header--not-bottom",
        frozen: "global-header--frozen",
    },
	onTop : function() {
		if (topBanner !== null){ 
			topBanner.style.display = "block";
			globalHeader.classList.add('global-header--alert');
		};
	},
	onNotTop : function() {
		if (topBanner !== null){ 
			topBanner.style.display = "none";
			globalHeader.classList.remove('global-header--alert');
		};
	},
});

fixedGlobalHeader.init();

if (reviewHeader) {
	const fixedReviewHeader = new Headroom(reviewHeader, {
		offset : 700,
	    classes : {
	        initial : "review-header--headroom",
	        pinned : "review-header--pinned",
	        unpinned : "review-header--unpinned",
	        top : "review-header--top",
	        notTop : "review-header--not-top",
	        bottom : "review-header--bottom",
	        notBottom : "review-header--not-bottom",
	        frozen: "review-header--frozen",
	    },
		onTop : function() {
			var reviewElem = document.querySelector('.review-header');
			var reviewWrapElem = document.querySelector('.review-header-wrap');
			reviewWrapElem.style.height = reviewElem.offsetHeight + 'px';
		},
	});

	fixedReviewHeader.init();
}

if (reviewNav) {
	const fixedReviewNav = new Headroom(reviewNav, {
		offset : 300,
	    classes : {
	        initial : "review-sidebar-nav--headroom",
	        pinned : "review-sidebar-nav--pinned",
	        unpinned : "review-sidebar-nav--unpinned",
	        top : "review-sidebar-nav--top",
	        notTop : "review-sidebar-nav--not-top",
	        bottom : "review-sidebar-nav--bottom",
	        notBottom : "review-sidebar-nav--not-bottom",
	        frozen: "review-sidebar-nav--frozen",
	    },
	});

	fixedReviewNav.init();
}

