jQuery(document).ready(function($) {
	
	$(".content table").wrap('<div class="table-scroll-container"></div>');
	
	// tablepress fix
	function setColumnWidths() {
	
		var numberOfColumns = $(".tablepress tr:first-child th").length;
	
		var minFirstColumnWidth = "130px";
	
		var availableWidth = $(".tablepress").width() - (100 * (numberOfColumns - 1)); // Subtract width of remaining columns
	
		var firstColumnWidth = Math.max(availableWidth * 0.15, minFirstColumnWidth);
		
		var remainingColumnWidth = (100 - (firstColumnWidth / $(".tablepress").width() * 100)) / (numberOfColumns - 1) + "%";
	
		$(".tablepress th:first-child, .tablepress td:first-child").css("width", firstColumnWidth);
		$(".tablepress th:not(:first-child), .tablepress td:not(:first-child)").css("width", remainingColumnWidth);
	
	}
	
	function setTableWidth() {
		if ($(window).width() <= 768) {
		  $(".tablepress").css("width", "708px");
		} else {
		  $(".tablepress").css("width", "100%");
		}
	}
	
	if ($(".tablepress").length > 0) {
	
		  setColumnWidths(); // Initial setup
		  setTableWidth(); // Initial setup
	
		  // Call setColumnWidths when the window is resized
		  $(window).on("resize", setColumnWidths);
		  
		  // Call setTableWidth when the window is resized
		  $(window).on("resize", setTableWidth);

	}
	
});
