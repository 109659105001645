function validUrl(str) {
  var res = str.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
  return (res !== null)
}

!function (j) {

  j(document).ready(function ()
  {

    j("#img-frm").submit(function (e) {
    	if (validUrl(document.getElementById('textbox-url').value)){
            j('#loader-container').css('display','flex');
            j('#error-area').css('display','none');
        }else{
            j('#error-area').css('display','block');
            e.preventDefault();
        }
    });

    j('table').each(function() {

            var headertext = [];
            var headers = this.querySelectorAll("thead");
            var tablebody = this.querySelectorAll("tbody");

            for(var i = 0; i < headers.length; i++) {
                    headertext[i]=[];
                    for (var j = 0, headrow; headrow = headers[i].rows[0].cells[j]; j++) {
                      var current = headrow;
                      headertext[i].push(current.textContent.replace(/\r?\n|\r/,""));
                      }
            }

            //console.log(headertext);

            if (headers.length > 0) {
                    for (var h = 0, tbody; tbody = tablebody[h]; h++) {
                            for (var i = 0, row; row = tbody.rows[i]; i++) {
                              for (var j = 0, col; col = row.cells[j]; j++) {
                                col.setAttribute("data-th", headertext[0][j]);
                              }
                            }
                    }
            }

            headertext = [];

        });

    })

}(jQuery);

