jQuery(document).ready(function($) {

	if ( typeof Swiper !== "undefined" ){

		// Feature carousel
		new Swiper('.feature-swiper', {
			slidesPerView: "auto",
			spaceBetween: 20,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			breakpoints: {
				1024: {
					spaceBetween: 100,
				},
			},
		});

		// Feature panel
		new Swiper('.image-swiper', {
			slidesPerView: "auto",
			spaceBetween: 100,
			navigation: {
				nextEl: '.image-swiper-button-next',
				prevEl: '.image-swiper-button-prev',
			},
		});

		// Team panel
		new Swiper('.team-swiper', {
			slidesPerView: "auto",
			spaceBetween: 30,
			autoHeight: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		});

		new Swiper('.meet-the-team-swiper', {
			slidesPerView: "auto",
			spaceBetween: 30,
			autoHeight: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		});

		// Gallery
		var galeryThumbsSwiper = new Swiper(".gallery-thumbs-swiper", {
			spaceBetween: 5,
			slidesPerView: "auto",
			freeMode: true,
			watchSlidesProgress: true,
		});

		var gallerySwiper = new Swiper(".gallery-swiper", {
			spaceBetween: 10,
			autoHeight: true,
			slidesPerView: "auto",
			thumbs: {
				swiper: galeryThumbsSwiper,
			},
		});

		// Examples Section Swipper Slider
		function create_examples_slider(){

			var swiper = new Swiper(".examples-slider", {
				spaceBetween: 0,
				slidesPerView: 1,
				pagination: {
					el: ".examples-pagination",
					clickable: true,
				},
				mousewheel: false,
				keyboard: true,
				simulateTouch: false,
				// cssMode: true,
				// freeMode: true,
				navigation: {
					nextEl: ".examples-slider-next",
					prevEl: ".examples-slider-prev",
				},
			});
		}

		function filter_change( filter ){
			//console.log(filter);
			if ( filter=="all" ){
				$("[data-filter]").removeClass("non-swiper-slide")
				.addClass("swiper-slide")
				.show();
			} else {
				$(".swiper-slide").not("[data-filter='"+filter+"']")
				.addClass("non-swiper-slide")
				.removeClass("swiper-slide")
				.hide();

				$("[data-filter='"+filter+"']").removeClass("non-swiper-slide")
				.addClass("swiper-slide")
				.attr("style", null)
				.show();
			}

			create_examples_slider();
		}

		// initial run
		create_examples_slider();

		$(".examples__category-list button").on("click", function(){

			$(".examples__category-list button").removeClass("is-active");
			$(this).addClass("is-active");

			var filter = $(this).data('industry');

			filter_change(filter);

		})

		$("#example-categories-dropdown").on("change", function(){

			var filter_list = document.getElementById("example-categories-dropdown");
			var filter = filter_list.options[filter_list.selectedIndex].value;

			filter_change(filter);

		});

	}

});
