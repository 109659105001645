jQuery(document).ready(function($) {
	$(".category-selector__select").change(function(){
		var slug = $(this).children("option:selected").val();
		if (slug) {
			window.location = slug;
		}
		return false;
    });
});

//This is the same as the language selector JS so it should probably be converted to a reusable component