jQuery(document).ready(function($) {
	$(document).on('click', ".load__more a", function (e) {
		e.preventDefault();
		var link = $(this).attr('href');
		$('.load__more a').text('Loading...');

		$.get(link, function(data) {
			var post = $("#posts .grid__cell ", data);
			$('#posts').append(post);
		});

		var next_page = $('.load__more').load(link+' .load__more a');
	});
});
