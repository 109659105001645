jQuery(document).ready(function($) {
	var previousActiveTabIndex = 0;
	$(".tabs__switcher").on('click keypress', function (event) {

		// event.which === 13 means the "Enter" key is pressed
		if ((event.type === "keypress" && event.which === 13) || event.type === "click") {

			var tabClicked = $(this).data("tab-index");

			$(this).siblings('.tabs__switcher').removeClass('is-active');
			$(this).addClass('is-active');

			if(tabClicked != previousActiveTabIndex) {
				$(this).parent().parent().find(".tabs__container .tabs__content").each(function () {
					if($(this).data("tab-index") == tabClicked) {
						$(this).siblings(".tabs__content").hide();
						$(this).show();
						previousActiveTabIndex = $(this).data("tab-index");
						return;
					}
				});
			}
		}
	});
});
