	let youtube = document.querySelectorAll(".youtube");

	for (let i = 0; i < youtube.length; i++) {

	  // Play video on click
	  youtube[i].addEventListener("click", function () {

		let iframe = document.createElement("iframe");

		iframe.setAttribute("frameborder", "0");
		iframe.setAttribute("allowfullscreen", "");
		iframe.setAttribute("src", "https://www.youtube.com/embed/" + this.dataset.embed + "?rel=0&showinfo=1&autoplay=1");

		this.innerHTML = "";
		this.appendChild(iframe);

	  });

	}