jQuery(document).ready(function($) {
	if($(".scroll-indicator").length) {
		var scrollTimer = null;

		$(window).scroll(function () {
			if (scrollTimer) {
				clearTimeout(scrollTimer);
			}
			scrollTimer = setTimeout(updateScrollIndicator, 10);
		});

		function updateScrollIndicator() {
			var scrollPosition = $(document).scrollTop();
			var pageHeight = $(document).height() - $(window).height();
			var progress = 100 * scrollPosition / pageHeight;

			$(".scroll-indicator").css("width", progress + "%");
		}
	}
});
