jQuery(document).ready(function($) {
	$('.nav-mobile__item-next').click(function(){
		$(this).parent('.nav-mobile__item').addClass('is-active');
		$(this).parent().parent('.nav-mobile__list').addClass('is-active');
	});

	$('.nav-mobile__item-next--full').click(function(){
		$(this).parent().parent('.nav-mobile__item').addClass('is-active');
		$(this).parent().parent().parent('.nav-mobile__list').addClass('is-active');
	});

	$('.nav-mobile__item-expand').click(function(){
		$(this).parent('.nav-mobile__item').toggleClass('is-active');
	});

	$('.nav-mobile__item--back, .nav-mobile__item--title').click(function(){
		$(this).parent().parent('.nav-mobile__item').removeClass('is-active');
		$('.nav-mobile--level-0').removeClass('is-active');
		$('.global-nav-mobile').animate({scrollTop: '0px'}, 0);
	});

	// Reset everything when nav is closed
	$('.global-header__menu-btn').click(function(){
		$('.nav-mobile__list').removeClass('is-active');
		$('.nav-mobile__item').removeClass('is-active');
	});

	// Search function on mobile
    $('.search-form').submit(function (e) {
        e.preventDefault();
        // get textbox. It's first element in form
        var input = $(this).find('.search-form__input');
        var lang = $(this).find('input[type="hidden"]');

        $('.bcs-searchbox').val(input.val());
        $('.bcs-searchbox-submit').click();

        $('.global-search').addClass('is-active');
    });
});
