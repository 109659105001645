jQuery(document).ready(function($) {
	
	$(".language-selector__select").change(function(){
		var slug = $(this).children("option:selected").val();
		var language = document.getElementById("language-select");
    		language.selectedIndex=0;
		if (slug) {
			window.location = slug;
		}
		return false;
    });
});
