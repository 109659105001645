if (typeof Fancybox !== "undefined") {

	Fancybox.bind('[data-fancybox="gallery"]', {
		Toolbar: false,
		animated: false,
		dragToClose: false,

		showClass: false,
		hideClass: false,

		closeButton: "top",

		Thumbs: false,

		Image: {
		click: "close",
		wheel: "slide",
		zoom: false,
		//   fit: "cover",
		},
	});

	Fancybox.bind('.gallery-item a, .gallery a', {
		Toolbar: false,
		animated: false,
		dragToClose: false,

		showClass: false,
		hideClass: false,

		closeButton: "top",

		Thumbs: false,

		Image: {
		click: "close",
		wheel: "slide",
		zoom: false,
		},
	});

}