jQuery(document).ready(function($) {
	$('#closeLanguageSwitcher').click(function(){
		$('#languageSwitcher').hide();
		$('.global-header').removeClass('global-header--alert');
		$.cookie('hideLS',1, { expires: 7, path: '/' });
	});

	if($.cookie('hideLS')==null) {
		if($('#languageSwitcher').length){
			$('#languageSwitcher').fadeIn();
			$('.global-header').addClass('global-header--alert');
		}
	}
});
