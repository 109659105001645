jQuery(document).ready(function($) {
	//function to close search modal when you click on body
	$(document.body).on('click', '.global-search.is-active' ,function(){
		if(!$(event.target).closest('.global-search__window').length) {
			$('body').removeClass('search-open');
			$('.global-search').removeClass('is-active');
		}
	});

	// Move cursor to the input box
	$('.global-header__seach-btn,.nav-mobile__item .search-form__input').click(function(){
		// Search Button
		
		if ( document.getElementById('bcs_js_snippet') === null){

			var search_script = document.createElement('script'); 
			var bing_id = document.getElementById('global-search-inner'); 
			var bing_src = bing_id.getAttribute('data-bing-src');
			
			search_script.setAttribute('src',bing_src);
			search_script.setAttribute('id','bcs_js_snippet');
			
			bing_id.appendChild(search_script);
		};

		searchCheckingInterval = setInterval(function() { 
			if ( document.getElementById("bcs-searchbox")  !== null ){
				 document.getElementById("bcs-searchbox").focus();
				 clearInterval(searchCheckingInterval); 
			}
		}, 200);
	});
});
