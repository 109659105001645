'use strict';

(function () {
    let debounce_timer;
    let backToTop = document.querySelector('.back-to-top');

	if (backToTop !== null) {
		window.onscroll = function () {
			if (debounce_timer) {
				window.clearTimeout(debounce_timer);
			}

			debounce_timer = window.setTimeout(function () {
				let fromTop = window.pageYOffset;
				if (fromTop > 700) {
					backToTop.classList.add('is-visible');
				} else {
					backToTop.classList.remove('is-visible');
				}
			}, 100);
		};
	}

}());
